export const environment = {
  name: "service  desarrollo it1 pruebas",
  host: "localhost",
  port: "8000",
  protocolo: "http",

  url_servers: {
    url_orchertractor:
      "https://v5mhyowde8.execute-api.sa-east-1.amazonaws.com/orchestrator",
    // "https://v5mhyowde8.execute-api.sa-east-1.amazonaws.com/binanceorchestrator",

    url_users: "https://dkjtral0z2.execute-api.sa-east-1.amazonaws.com/users",

    url_library:
      "https://5hl2u11ayf.execute-api.sa-east-1.amazonaws.com/library",

    url_operation: "https://operationstb.thisisvank.com/operations/secure/v1",

    url_usersAWS:
      "https://dkjtral0z2.execute-api.sa-east-1.amazonaws.com/users",

    url_prints: "https://printsstb.thisisvank.com",

    url_fiat: "https://yskkfj5yfl.execute-api.sa-east-1.amazonaws.com",

    url_upload_excel: "https://nvlzhnmj-3033.use2.devtunnels.ms",

    url_batch_template: "https://templatesvank.s3.us-west-2.amazonaws.com",
  },

  url_externas: {
    url_realTimeValue:
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd",

    url_binance: "https://tlkh3ywbu5.execute-api.sa-east-1.amazonaws.com",

    //url_incode: "https://mxt1qql79a.execute-api.sa-east-1.amazonaws.com/incode",
    url_incode:
      " https://4vbjczjypd.execute-api.sa-east-1.amazonaws.com/incode",
  },
};
